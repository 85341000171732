
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat-typography-config(
  $font-family: 'PT Sans'
);
@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


// Custom Color Primary
$aa-blue: (
    50: #ff6e00,
    100: #ff6e00,
    200: #ff6e00,
    300: #ff6e00,
    400: #ff6e00,
    500: #ff6e00,
    600: #ff6e00,
    700: #ff6e00,
    800: #ff6e00,
    900: #ff6e00,
    A100: #ff6e00,
    A200: #ff6e00,
    A400: #ff6e00,
    A700: #ff6e00,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);

// Custom Color Secondary
$aa-cyan: (
    50: #00AEEF,
    100: #00AEEF,
    200: #00AEEF,
    300: #00AEEF,
    400: #00AEEF,
    500: #00AEEF,
    600: #00AEEF,
    700: #00AEEF,
    800: #00AEEF,
    900: #00AEEF,
    A100: #00AEEF,
    A200: #00AEEF,
    A400: #00AEEF,
    A700: #00AEEF,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);
mat-card.mat-card-form{
  width:100% !important;
  max-width: 1300px !important;
  margin:0 auto !important;
  box-shadow: none!important; 
  border-radius: 20px !important; 
  background-color: rgba(255, 255, 255, 0.9) !important;
  -webkit-backdrop-filter: blur(30px) !important;
  backdrop-filter: blur(30px) !important;
}
$agendamiento-peru-primary: mat-palette($aa-blue);
$agendamiento-peru-accent: mat-palette($aa-cyan);

// The warn palette is optional (defaults to red).
$agendamiento-peru-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$agendamiento-peru-theme: mat-light-theme((
  color: (
    primary: $agendamiento-peru-primary,
    accent: $agendamiento-peru-accent,
    warn: $agendamiento-peru-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($agendamiento-peru-theme);


*, *::before, *::after {
    box-sizing: border-box;
}

::ng-deep .mat-tooltip  {
  white-space: pre-line !important;
}

.tooltip-class{
  white-space: pre-line !important;
}

html {
  font-family: 'PT Sans', sans-serif!important;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Work Sans', sans-serif!important;
  font-weight: normal !important;
}

body {
  margin: 2em;
  bottom: 50px;
  background-color: rgba(255, 255, 255, 0.85);
  height: 100%;
}

.main-content {
  height: inherit;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0 0 4px rgb(0 0 0 / 30%);
}

hr {
  opacity: 0.1;
}

body {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  padding-bottom: 60px;
}

.mat-snack-bar-container {
  opacity: 1 !important;
  color: #fff !important;
  background: #535F6A !important;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #000 !important;
}
.mat-simple-snack-bar-content{
  color: #000000DE;
}
.mat-radio-outer-circle{
width: 14px !important;
height: 14px !important;
}
.mat-radio-label-content{
  font-family: 'PT Sans';
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
 
}
.mat-radio-container{
  margin-top: 5px !important;
}
.mat-radio-inner-circle{
  top: -3px !important;
  left: -3px !important;
}

.mat-snack-bar-container {
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 24px;
  max-width: 33vw;
  min-width: 300px !important;
  padding: 14px 16px;
  min-height: 48px;
  transform-origin: center;
}

/*
.mat-datepicker-content .mat-calendar {
  width: 208px !important;
  height: 250px !important;
}*/
.mat-calendar-body-selected{
  background-color: transparent !important;
  border: 1px solid #FF6E00;
  color: #FF7200;
}
.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  border-color: 1px solid #333333 !important;
  color: #333333 !important;
}
.mat-radio-checked .mat-radio-label-content{
  color: #333333 !important;
}


.mat-calendar-header {
  padding: 0px 8px 0 8px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.modal-promocion .img-fluid {
  max-width: 600px;
  /* width: 100%; */
}

html, body { height: 100%; }
body { margin: 0; font-family: 'PT Sans', sans-serif;}


/*Stepper*/
.mat-step-icon{
  width: 50px!important;
  height: 50px !important;
}

.mat-step-icon-state-edit{
  background-color: transparent!important;
  border: 1px solid #ff6e00 !important;
}
.mat-horizontal-stepper-header-container{
  border-bottom: 1px solid #333333;;
  padding-bottom: 15px;
}

.mat-stepper-horizontal-line {
  border-top-color: transparent!important;
}

.mat-button{
  border-radius: 2px!important;
}
.mat-stepper-next, .agendar-button{
  width: 120px!important;
  background: #0066CC !important;
  border-radius: 16px !important;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
}

.mat-step-header .mat-step-icon { 
  border: 1px solid #333333;
  font-weight: 900; 
  color: #333333;
  background:transparent; 
}
.mat-row-stepper .mat-horizontal-stepper-wrapper{
  flex-direction: row;
}
.mat-row-stepper .form-buttons-container{
  border: none !important;
}
.mat-row-stepper .mat-horizontal-stepper-header-container{
  display: block !important;
}
.mat-row-stepper  .mat-horizontal-stepper-header-container{
  border-right: 1px solid #333333 !important;
  border-bottom: none !important; 
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.mat-row-stepper .mat-horizontal-stepper-header .mat-step-icon{
  width: 21px !important;
  height: 21px !important;
}
.mat-row-stepper .mat-horizontal-content-container{
  padding: 0 15px 0px 22px !important;}

.mat-row-stepper  .mat-horizontal-stepper-wrapper{
  border-top: 1px solid #333333;
  margin-bottom: 50px;
}
.mat-row-stepper form{
  margin-bottom: 20px !important;
}


.mat-step-header .mat-step-icon-selected {
  border: 1px solid #FF6E00;
  background: transparent;
  font-weight: 900;  
  color: #FF6E00;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle, .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple{
  background: transparent !important;
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle{
  border: 4.9px solid #0066CC !important; 
}


.mat-stepper-previous{
  width: 120px!important;
  height: 35.95px !important;
  background-color: transparent;
  color: #333333;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-right:5px!important;
  border: 1px solid #333333 !important;
  border-radius: 16px !important;
}

.white-snackbar{
  background-color: #ffffff!important;
  color: black!important;
  font-weight: bold !important;
}

/*Grid*/
.mat-grid-tile-content {
  justify-content: left!important;
}

/*Calendar*/
.mat-calendar{
  padding: 5px;
  border: 1px solid rgba(0,0,0,0.12);
  border-radius:4px;
}

/*Dialog*/
.mat-dialog-container{
  border-radius: 20px!important;
}

.mat-dialog-actions{
  margin-bottom:0px!important;
}

.popup-close-header{
  display:flex;
  justify-content: flex-end;
}

.popup-close{
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 767px) {

  .mat-horizontal-stepper-header {
      height: 50px!important;
      justify-content: center!important;
  }

  .mat-stepper-horizontal-line {
      display: none;
  }

  .mat-horizontal-stepper-header-container{
      display: block !important;;
  }

  .mat-step-header[aria-selected=false]{
      display: none !important;
  }


  .mat-horizontal-content-container {
      padding: 0 1px 24px 1px!important;
  }
  
  .mat-row-stepper .mat-horizontal-stepper-wrapper{
    flex-direction: column !important;
  }
  .mat-row-stepper .mat-horizontal-stepper-header-container{
    border: none !important;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline{
  color:#333333 !important;
}


.mat-input-element:disabled:first-letter{
  color: red !important;;
}